import * as APIt from "../API";
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { DeviceSources, DeviceTypes, OnguardReaderMaskActionTypes, Paths, Regions } from 'src/constants/Constants';
import { MaskingGroupInfo, SilencioDevice } from "./SilencioTypes";
import { debug, getPACSDataAPIStage } from './commonUtils';
import { getDevice, getLocalIORelations, listChildDevices, listDeviceStatusesForSite } from '../graphql/queries'
import {
  setReaderMode as setReaderModeMutation,
  setInputMask as setInputMaskMutation,
  setOutputCommand as setOutputCommandMutation,
  setReaderDoorHeldMask as setReaderDoorHeldMaskMutation,
  setReaderDoorForcedMask as setReaderDoorForcedMaskMutation,
  setPACSDataAPIReaderMask as setPACSDataAPIReaderMaskMutation,
  upsertMaskingGroup as upsertMaskingGroupMutation,
  deleteMaskingGroup as deleteMaskingGroupMutation
} from '../graphql/mutations'
import { UnifiedDeviceStatus } from '@amzn/gso-device-status';
import { UserActionNames } from 'src/constants/Constants';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { unmarshall } from '@aws-sdk/util-dynamodb';
import { DeviceMutationResponseInterface } from "./SilencioTypes";

export const addOrUpdateDevice = (devices: SilencioDevice[], device: SilencioDevice) => {
  const index = devices.findIndex(d => d.Parent_DeviceID === device.Parent_DeviceID
    && d.Child_DeviceID === device.Child_DeviceID
    && d.Subchild_DeviceID === device.Subchild_DeviceID);

  if (index == -1) {
    devices.push(device);
  } else {
    if (device.MaskingGroups && device.MaskingGroups.length > 0) {
      if (devices[index].MaskingGroups) {
        devices[index].MaskingGroups = devices[index].MaskingGroups?.concat(device.MaskingGroups);
      } else {
        devices[index].MaskingGroups = device.MaskingGroups;
      }
    }
  }
}

export const addPlaceholderDevice = (devices: SilencioDevice[], device: SilencioDevice) => {
  const index = devices.findIndex(d => d.Parent_DeviceID === device.Parent_DeviceID
    && d.Child_DeviceID === device.Child_DeviceID
    && d.Subchild_DeviceID === device.Subchild_DeviceID);

  if (index == -1) {
    devices.push({
      ...device,
      MaskingGroups: null
    });
  }
}

export const consolidatePairedReaders = (readersAlarms: SilencioDevice[]): SilencioDevice[] => {
  const consolidatedReadersAlarms: SilencioDevice[] = [];
  readersAlarms.forEach(device => {
    if (device.pair_primary && device.paired_reader_id) {
      const pairedReader = readersAlarms.find(d => d.Parent_DeviceID === device.Parent_DeviceID && d.Child_DeviceID === device.paired_reader_id);
      const maskingGroups: MaskingGroupInfo[] = device.MaskingGroups ? device.MaskingGroups : [];
      if (pairedReader && pairedReader.MaskingGroups) {
        pairedReader.MaskingGroups.forEach(maskingGroup => {
          if (!maskingGroups.find(mg => mg.id == maskingGroup.id)) maskingGroups.push(maskingGroup);
        });
      }
      consolidatedReadersAlarms.push({
        ...device,
        PairedReader: pairedReader?.DeviceName,
        MaskingGroups: maskingGroups
      })
    } else if (!device.pair_secondary) {
      consolidatedReadersAlarms.push(device);
    }
  });
  return consolidatedReadersAlarms;
}

const getOutputStatus = (d: APIt.ChildDevice, dStatus: UnifiedDeviceStatus | undefined): string | undefined => {
  if (dStatus && DeviceTypes.Outputs.includes(d.Device_Type!)) {
    return dStatus.relay_contact ? 'Activated' : 'Deactivated';
  } else {
    return undefined;
  }
}

export const fetchDevice = async (amzn_key: string, siteRegion: string | null): Promise<SilencioDevice | undefined> => {
  debug(`fetchDevice() amzn_key is ${amzn_key} siteRegion is ${siteRegion}`);
  if (!amzn_key) return undefined;

  let stage = 'beta';
  let devices: APIt.ChildDevice[] = [];
  let device: SilencioDevice | undefined;

  try {
    for (let region of Regions) {
      if (!siteRegion || region === siteRegion) {
        const devicesResponse = await API.graphql(graphqlOperation(getDevice,
          {
            path: `${Paths.GetDevice}/region/${region}/stage/${getPACSDataAPIStage(stage)}/?silencio_amzn_key=${amzn_key}&OutputFormat=json`
          })) as GraphQLResult<APIt.GetDeviceQuery>;
        debug(`fetchDevice() devicesResponse is: ${JSON.stringify(devicesResponse)}`);
        devices = [...devices, ...devicesResponse.data?.getDevice as APIt.ChildDevice[]];
      }
      if (devices.length > 0) break;
    }
    debug(`fetchDevice() devices is: ${JSON.stringify(devices)}`);

    if (devices.length === 0) {
      debug(`fetchDevice() Error: no devices found.`);
    } else if (devices.length > 1) {
      debug(`fetchDevice() multiple devices found, choosing keep device.`);
      device = devices.find(d => d.devicesource === DeviceSources.KEEP) as SilencioDevice;
    } else {
      device = devices[0] as SilencioDevice;
    }
    debug(`fetchDevice() device is: ${JSON.stringify(device)}`);
    return device;
  } catch (error) {
    console.error(`fetchDevice() Error: ${JSON.stringify(error)}`);
    throw error;
  }
}

export const fetchDevices = async (sitename: string, siteRegion: string | null): Promise<SilencioDevice[]> => {
  debug(`fetchDevices() sitename is ${sitename} siteRegion is ${siteRegion}`);
  if (!sitename) return [];

  let stage = 'beta';
  let devices: APIt.ChildDevice[] = [];

  try {
    for (let region of Regions) {
      if (!siteRegion || region === siteRegion) {
        const devicesResponse = await API.graphql(graphqlOperation(listChildDevices,
          {
            path: `${Paths.GetDevices}/region/${region}/stage/${getPACSDataAPIStage(stage)}/?SiteCode=${sitename}&OutputFormat=json` // TODO: pull region from site
          })) as GraphQLResult<APIt.ListChildDevicesQuery>;
        debug(`fetchDevices() devicesResponse is: ${JSON.stringify(devicesResponse)}`);
        devices = [...devices, ...devicesResponse.data?.listChildDevices as APIt.ChildDevice[]];
      }
      if (devices.length > 0) break;
    }
    debug(`fetchDevices() devices is: ${JSON.stringify(devices)}`);

    const deviceStatuses: UnifiedDeviceStatus[] = await fetchGSODeviceStatusesForSite(sitename);
    debug(`fetchDevices() deviceStatuses is: ${JSON.stringify(deviceStatuses)}`);

    const silencioDevices: SilencioDevice[] = devices.map(d => {
      debug(`fetchDevices() devices.map d is: ${JSON.stringify(d)}`);
      const dStatus = deviceStatuses.find(ds => (ds.parent_device_id == d.Parent_DeviceID
        && ds.child_device_id == d.Child_DeviceID
        && ds.subchild_device_id == d.Subchild_DeviceID));
      debug(`fetchDevices() devices.map dStatus is: ${JSON.stringify(dStatus)}`);
      const mergedDevice: SilencioDevice = {
        ...d,
        DoorForcedMasked: dStatus?.door_forced_masked === null ? null : dStatus?.door_forced_masked,
        DoorHeldMasked: dStatus?.door_held_masked === null ? null : dStatus?.door_held_masked,
        Masked: dStatus?.masked === null ? null : dStatus?.masked,
        Mode: dStatus?.reader_mode === null ? null : dStatus?.reader_mode,
        MaskingGroups: (d.MaskGroupName && d.MaskGroupType && d.MaskGroupID)
          ? [{ id: d.MaskGroupID, name: d.MaskGroupName, type: d.MaskGroupType }]
          : null,
        OutputStatus: getOutputStatus(d, dStatus),
      };
      debug(`fetchDevices() mergedDevice d is: ${JSON.stringify(mergedDevice)}`);
      return mergedDevice;
    });
    debug(`fetchDevices() silencioDevices is: ${JSON.stringify(silencioDevices)}`);
    return silencioDevices;
  } catch (error) {
    console.error(`fetchDevices() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
    throw error;
  }
}

export const fetchGSODeviceStatusesForSite = async (siteCode: string): Promise<UnifiedDeviceStatus[]> => {
  debug(`fetchGSODeviceStatusesForSite() siteCode is ${siteCode}`);

  let deviceStatuses: UnifiedDeviceStatus[] = [];

  if (!siteCode) return deviceStatuses;

  try {
    let lastEvaluatedKey = undefined;
    do {
      const deviceStatusesResponse = await API.graphql(graphqlOperation(listDeviceStatusesForSite,
        {
          siteCode: `${siteCode}`,
          startKey: JSON.stringify({ start_key: lastEvaluatedKey })
        })) as GraphQLResult<APIt.ListDeviceStatusesForSiteQuery>;
      debug(`fetchGSODeviceStatusesForSite() deviceStatusesResponse is ${JSON.stringify(deviceStatusesResponse)}`);
      const deviceStatusesResult = deviceStatusesResponse.data?.listDeviceStatusesForSite as APIt.GSODeviceStatusesForSite;
      debug(`fetchGSODeviceStatusesForSite() deviceStatusesCount is ${JSON.stringify(deviceStatusesResult.Count)}`);
      if (deviceStatusesResult && deviceStatusesResult.Items) {
        deviceStatusesResult.Items.forEach(item => {
          if (item) {
            deviceStatuses.push(unmarshall(JSON.parse(item)) as UnifiedDeviceStatus);
          }
        });
      }
      lastEvaluatedKey = deviceStatusesResult.LastEvaluatedKey ? JSON.parse(deviceStatusesResult.LastEvaluatedKey) : undefined;
    } while (lastEvaluatedKey);
  } catch (error) {
    console.error(`fetchGSODeviceStatusesForSite() error is ${error} JSON.stringify ${JSON.stringify(error)}`);
    throw error;
  }
  return deviceStatuses;
}

export const fetchIORelations = async (inputs: SilencioDevice[], outputs: SilencioDevice[], sitename: string, siteRegion: string): Promise<SilencioDevice[]> => {
  debug(`fetchIORelations() sitename is ${sitename} siteRegion is ${siteRegion}`);
  if (!sitename || !siteRegion) return inputs;

  let stage = 'beta';
  let ioRelations: APIt.LocalIORelation[] = [];

  try {
    const localIORelationsResponse = await API.graphql(graphqlOperation(getLocalIORelations,
      {
        path: `${Paths.GetLocalIORelations}/region/${siteRegion}/stage/${getPACSDataAPIStage(stage)}/?sitecode=${sitename}&OutputFormat=json`
      })) as GraphQLResult<APIt.GetLocalIORelationsQuery>;
    debug(`fetchIORelations() localIORelationsResponse is: ${JSON.stringify(localIORelationsResponse)}`);
    ioRelations = [...ioRelations, ...localIORelationsResponse.data?.getLocalIORelations as APIt.LocalIORelation[]];
    for (let ioRelation of ioRelations) {
      debug(`fetchIORelations() handling ioRelation: ${JSON.stringify(ioRelation)}`);
      const input = inputs.find((input) => (
        input.Parent_DeviceID === ioRelation.input_device_parent_id &&
        input.Child_DeviceID === ioRelation.input_device_child_id &&
        input.Subchild_DeviceID === ioRelation.input_device_sub_child_id
      ));
      const output = outputs.find((output) => (
        output.Parent_DeviceID === ioRelation.output_device_parent_id &&
        output.Child_DeviceID === ioRelation.output_device_child_id &&
        output.Subchild_DeviceID === ioRelation.output_device_sub_child_id
      ));
      if (input !== undefined && output !== undefined && output.DeviceName) {
        if (input.IORelationOutputNames) {
          input.IORelationOutputNames.push(output.DeviceName);
          debug(`fetchIORelations() pushing ${output.DeviceName} to input ${input.DeviceName}`);
        } else {
          input.IORelationOutputNames = [output.DeviceName];
          debug(`fetchIORelations() adding ${output.DeviceName} as first output on input ${input.DeviceName}`);
        }
      } else {
        debug(`fetchIORelations() input or output not found. Input: ${input?.DeviceName} Output: ${output?.DeviceName}`);
      }
    }
  } catch (error) {
    debug(`fetchIORelations() Error: ${error}`);
  }
  return inputs;
}

export const deleteMaskingGroup = async (
  siteRegion: string,
  requestedBy: string,
  maskingGroupName: string,
  maskingGroupType: string
): Promise<APIt.PACSMaskingGroupResponse> => {
  debug(`deleteMaskingGroup() siteRegion is ${siteRegion}, maskingGroupName is ${maskingGroupName}, maskingGroupType is ${maskingGroupType}`);
  let stage = 'beta';
  try {
    if (!siteRegion) throw new Error(`siteRegion is required. Found: ${siteRegion}`);
    if (!maskingGroupName) throw new Error(`maskingGroupName is required. Found: ${maskingGroupName}`);
    if (!maskingGroupType) throw new Error(`maskingGroupType is required. Found: ${maskingGroupType}`);
    const parameters: string[] = [
      `MaskGroupName=${encodeURIComponent(maskingGroupName)}`,
      `MaskGroupType=${maskingGroupType}`,
      'outputFormat=json'
    ];

    createUserAction({
      actionName: UserActionNames.DeleteMaskingGroup,
      username: requestedBy,
      parameters: JSON.stringify({ input: parameters })
    });

    const response = await API.graphql(graphqlOperation(deleteMaskingGroupMutation,
      {
        path: `${Paths.DeleteMaskGroup}/region/${siteRegion}/stage/${getPACSDataAPIStage(stage)}/?${parameters.join('&')}`
      })) as GraphQLResult<APIt.DeleteMaskingGroupMutation>;
    debug(`deleteMaskingGroup() response is ${JSON.stringify(response)}`);
    createUserAction({
      actionName: UserActionNames.DeleteMaskingGroupResult,
      username: requestedBy,
      parameters: JSON.stringify({ input: parameters, response })
    });
    if (response.data && response.data.deleteMaskingGroup && response.data.deleteMaskingGroup.length == 1) {
      return (response.data.deleteMaskingGroup as APIt.PACSMaskingGroupResponse[])[0];
    } else {
      throw new Error(response.errors?.toString() ?? 'Error parsing DeleteMaskingGroupMutation response.');
    }
  } catch (error) {
    console.error(`deleteMaskingGroup() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
    createUserAction({
      actionName: UserActionNames.DeleteMaskingGroupError,
      username: requestedBy,
      parameters: JSON.stringify({ error, maskingGroupName, maskingGroupType })
    });
    throw error;
  }
}

export const upsertMaskingGroup = async (
  siteRegion: string,
  maskingGroupName: string,
  maskingGroupType: string,
  requestedBy: string,
  newMaskingGroupName: string | null,
  addDevices: SilencioDevice[] | null,
  removeDevices: SilencioDevice[] | null
): Promise<APIt.PACSMaskingGroupResponse> => {
  debug(`upsertMaskingGroup() siteRegion is ${siteRegion}, maskingGroupName is ${maskingGroupName}, maskingGroupType is ${maskingGroupType}, newMaskingGroupName is ${newMaskingGroupName}, addDevices is ${addDevices}, removeDevices is ${removeDevices}`);
  const input = { maskingGroupName, maskingGroupType, newMaskingGroupName, addDevices, removeDevices };
  let stage = 'beta';
  try {
    if (!siteRegion) throw new Error(`siteRegion is required. Found: ${siteRegion}`);
    if (!maskingGroupName) throw new Error(`maskingGroupName is required. Found: ${maskingGroupName}`);
    if (!maskingGroupType) throw new Error(`maskingGroupType is required. Found: ${maskingGroupType}`);
    const parameters: string[] = [
      `MaskGroupName=${encodeURIComponent(maskingGroupName)}`,
      `MaskGroupType=${maskingGroupType}`
    ];
    if (newMaskingGroupName) {
      debug(`upsertMaskingGroup() will rename ${maskingGroupName} to ${newMaskingGroupName}.`);
      parameters.push(`NewMaskGroupName=${encodeURIComponent(newMaskingGroupName)}`);
    }
    if (addDevices) {
      debug(`upsertMaskingGroup() will add ${addDevices.length} devices.`);
      parameters.push(`AddList=${addDevices.map(d => `${d.Parent_DeviceID}.${d.Child_DeviceID}.${d.Subchild_DeviceID}`).join(',')}`);
    }
    if (removeDevices) {
      debug(`upsertMaskingGroup() will remove ${removeDevices.length} devices.`);
      parameters.push(`DeleteList=${removeDevices.map(d => `${d.Parent_DeviceID}.${d.Child_DeviceID}.${d.Subchild_DeviceID}`).join(',')}`);
    }

    createUserAction({
      actionName: UserActionNames.UpsertMaskingGroup,
      username: requestedBy,
      parameters: JSON.stringify({ input })
    });

    parameters.push('outputFormat=json');
    const response = await API.graphql(graphqlOperation(upsertMaskingGroupMutation,
      {
        path: `${Paths.EditMaskGroup}/region/${siteRegion}/stage/${getPACSDataAPIStage(stage)}/?${parameters.join('&')}`
      })) as GraphQLResult<APIt.UpsertMaskingGroupMutation>;
    debug(`upsertMaskingGroup() response is ${JSON.stringify(response)}`);
    createUserAction({
      actionName: UserActionNames.UpsertMaskingGroupResult,
      username: requestedBy,
      parameters: JSON.stringify({ input, response })
    });
    if (response.data && response.data.upsertMaskingGroup && response.data.upsertMaskingGroup.length == 1) {
      return (response.data.upsertMaskingGroup as APIt.PACSMaskingGroupResponse[])[0];
    } else {
      throw new Error(response.errors?.toString() ?? 'Error parsing UpsertMaskingGroupMutation response.');
    }
  } catch (error) {
    console.error(`upsertMaskingGroup() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
    createUserAction({
      actionName: UserActionNames.UpsertMaskingGroupError,
      username: requestedBy,
      parameters: JSON.stringify({ error, input })
    });
    throw error;
  }
}

export const setReaderMode = async (
  deviceSource: string,
  devices: { deviceName: string, deviceHref: string | undefined }[],
  emails: string,
  mode: string,
  processWithDelaySeconds: number,
  requestedBy: string,
  siteName: string,
  siteRegion: string): Promise<DeviceMutationResponseInterface> => {
  debug(`setReaderMode() deviceSource is ${deviceSource} devices is ${JSON.stringify(devices)} emails is ${emails} mode is ${JSON.stringify(mode)} processWithDelaySeconds is ${processWithDelaySeconds} requestedBy is ${requestedBy} siteName is ${siteName} siteRegion is ${siteRegion}`);

  let setReaderModeResponse: DeviceMutationResponseInterface = {
    devices: devices.map(d => {
      return {
        __typename: 'SetDeviceResponse',
        deviceName: d.deviceName,
        status: null
      }
    }),
    error: null,
    requestUUID: null,
    status: null
  };

  return new Promise(async (resolve, reject) => {
    const input = {
      deviceSource: deviceSource,
      devices: devices,
      emails: emails,
      method: 'put',
      mode: mode,
      path: Paths.DeviceMode,
      processWithDelaySeconds: processWithDelaySeconds,
      requestedBy: requestedBy,
      siteName: siteName,
      siteRegion: siteRegion
    };
    try {
      createUserAction(
        {
          actionName: UserActionNames.SetReaderMode,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              input: input
            })
        });
      const response = await API.graphql(graphqlOperation(setReaderModeMutation,
        {
          input: input
        })) as GraphQLResult<APIt.SetReaderModeMutation>;
      createUserAction(
        {
          actionName: UserActionNames.SetReaderModeResult,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              response: response,
              input: input
            })
        });
      debug(`setReaderMode() response is ${JSON.stringify(response)}`);
      setReaderModeResponse.status = response.data?.setReaderMode?.status;
      setReaderModeResponse.requestUUID = response.data?.setReaderMode?.requestUUID;
      if (response.data?.setReaderMode?.devices)
        setReaderModeResponse.devices = response.data?.setReaderMode?.devices as APIt.SetDeviceResponse[];
      debug(`setReaderMode() setReaderModeResponse is ${JSON.stringify(setReaderModeResponse)}`);
      resolve(setReaderModeResponse);
    } catch (error: any) {
      console.error('setReaderMode() error is ', error);
      createUserAction(
        {
          actionName: UserActionNames.SetReaderModeError,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              error: error,
              input: input
            })
        });
      if (error.errors[0]) {
        setReaderModeResponse.error = error.errors[0].message;
      } else {
        setReaderModeResponse.error = error;
      }
      reject(setReaderModeResponse);
    }
  });
}

export const setInputMask = async (
  deviceSource: string,
  devices: { deviceName: string, deviceHref: string | undefined }[],
  emails: string,
  masked: boolean,
  processWithDelaySeconds: number,
  requestedBy: string,
  siteName: string,
  siteRegion: string): Promise<DeviceMutationResponseInterface> => {
  debug(`setInputMask() deviceSource is ${deviceSource} devices is ${JSON.stringify(devices)} emails is ${emails} masked is ${masked} processWithDelaySeconds is ${processWithDelaySeconds} requestedBy is ${requestedBy} siteName is ${siteName} siteRegion is ${siteRegion}`);

  let setInputMaskResponse: DeviceMutationResponseInterface = {
    devices: devices.map(d => {
      return {
        __typename: 'SetDeviceResponse',
        deviceName: d.deviceName,
        status: null
      }
    }),
    error: null,
    requestUUID: null,
    status: null
  };

  return new Promise(async (resolve, reject) => {
    const input = {
      deviceSource: deviceSource,
      devices: devices,
      emails: emails || '',
      masked: masked,
      method: 'post',
      path: Paths.InputMask,
      processWithDelaySeconds: processWithDelaySeconds,
      requestedBy: requestedBy,
      siteName: siteName,
      siteRegion: siteRegion
    };
    try {
      createUserAction(
        {
          actionName: UserActionNames.SetInputMask,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              input: input
            })
        });
      debug(`setInputMask() input is ${JSON.stringify(input)}`);
      const response = await API.graphql(graphqlOperation(setInputMaskMutation,
        {
          input: input
        })) as GraphQLResult<APIt.SetInputMaskMutation>;
      createUserAction(
        {
          actionName: UserActionNames.SetInputMaskResult,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              response: response,
              input: input
            })
        });
      debug(`setInputMask() response is ${JSON.stringify(response)}`);
      setInputMaskResponse.status = response.data?.setInputMask?.status;
      setInputMaskResponse.requestUUID = response.data?.setInputMask?.requestUUID;
      if (response.data?.setInputMask?.devices)
        setInputMaskResponse.devices = response.data?.setInputMask?.devices as APIt.SetDeviceResponse[];
      debug(`setInputMask() setInputMaskResponse is ${JSON.stringify(setInputMaskResponse)}`);
      resolve(setInputMaskResponse);
    } catch (error: any) {
      console.error('setInputMask() error is ', error);
      createUserAction(
        {
          actionName: UserActionNames.SetInputMaskError,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              error: error,
              input: input
            })
        });
      if (error.errors[0]) {
        setInputMaskResponse.error = error.errors[0].message;
      } else {
        setInputMaskResponse.error = error;
      }
      reject(setInputMaskResponse);
    }
  });
}

export const setReaderDoorHeldMask = async (
  deviceSource: string,
  devices: { deviceName: string, deviceHref: string | undefined }[],
  emails: string,
  masked: boolean,
  processWithDelaySeconds: number,
  requestedBy: string,
  siteName: string,
  siteRegion: string): Promise<DeviceMutationResponseInterface> => {
  debug(`setReaderDoorHeldMask() deviceSource is ${deviceSource} devices is ${JSON.stringify(devices)} emails is ${emails} masked is ${masked} processWithDelaySeconds is ${processWithDelaySeconds} requestedBy is ${requestedBy} siteName is ${siteName} siteRegion is ${siteRegion}`);

  let setReaderDoorHeldMaskResponse: DeviceMutationResponseInterface = {
    devices: devices.map(d => {
      return {
        __typename: 'SetDeviceResponse',
        deviceName: d.deviceName,
        status: null
      }
    }),
    error: null,
    requestUUID: null,
    status: null
  };

  return new Promise(async (resolve, reject) => {
    const input = {
      deviceSource: deviceSource,
      devices: devices,
      emails: emails,
      masked: masked,
      method: 'post',
      path: Paths.ReaderDoorHeld,
      processWithDelaySeconds: processWithDelaySeconds,
      requestedBy: requestedBy,
      siteName: siteName,
      siteRegion: siteRegion
    };
    try {
      createUserAction(
        {
          actionName: UserActionNames.SetReaderDoorHeldMask,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              input: input
            })
        });
      debug(`setReaderDoorHeldMask() input is ${JSON.stringify(input)}`);
      const response = await API.graphql(graphqlOperation(setReaderDoorHeldMaskMutation,
        {
          input: input
        })) as GraphQLResult<APIt.SetReaderDoorHeldMaskMutation>;
      createUserAction(
        {
          actionName: UserActionNames.SetReaderDoorHeldMaskResult,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              response: response,
              input: input
            })
        });
      debug(`setReaderDoorHeldMask() response is ${JSON.stringify(response)}`);
      setReaderDoorHeldMaskResponse.status = response.data?.setReaderDoorHeldMask?.status;
      setReaderDoorHeldMaskResponse.requestUUID = response.data?.setReaderDoorHeldMask?.requestUUID;
      if (response.data?.setReaderDoorHeldMask?.devices)
        setReaderDoorHeldMaskResponse.devices = response.data?.setReaderDoorHeldMask?.devices as APIt.SetDeviceResponse[];
      debug(`setReaderDoorHeldMask() setReaderDoorHeldMaskResponse is ${JSON.stringify(setReaderDoorHeldMaskResponse)}`);
      resolve(setReaderDoorHeldMaskResponse);
    } catch (error: any) {
      console.error('setReaderDoorHeldMask() error is ', error);
      createUserAction(
        {
          actionName: UserActionNames.SetReaderDoorHeldMaskError,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              error: error,
              input: input
            })
        });
      if (error.errors[0]) {
        setReaderDoorHeldMaskResponse.error = error.errors[0].message;
      } else {
        setReaderDoorHeldMaskResponse.error = error;
      }
      reject(setReaderDoorHeldMaskResponse);
    }
  });
}

export const setReaderDoorForcedMask = async (
  deviceSource: string,
  devices: { deviceName: string, deviceHref: string | undefined }[],
  emails: string,
  masked: boolean,
  processWithDelaySeconds: number,
  requestedBy: string,
  siteName: string,
  siteRegion: string): Promise<DeviceMutationResponseInterface> => {
  debug(`setReaderDoorForcedMask() deviceSource is ${deviceSource} devices is ${JSON.stringify(devices)} emails is ${emails} masked is ${masked} processWithDelaySeconds is ${processWithDelaySeconds} requestedBy is ${requestedBy} siteName is ${siteName} siteRegion is ${siteRegion}`);

  let setReaderDoorForcedMaskResponse: DeviceMutationResponseInterface = {
    devices: devices.map(d => {
      return {
        __typename: 'SetDeviceResponse',
        deviceName: d.deviceName,
        status: null
      }
    }),
    error: null,
    requestUUID: null,
    status: null
  };

  return new Promise(async (resolve, reject) => {
    const input = {
      deviceSource: deviceSource,
      devices: devices,
      emails: emails,
      masked: masked,
      method: 'post',
      path: Paths.ReaderDoorForced,
      processWithDelaySeconds: processWithDelaySeconds,
      requestedBy: requestedBy,
      siteName: siteName,
      siteRegion: siteRegion
    };
    try {
      createUserAction(
        {
          actionName: UserActionNames.SetReaderDoorForcedMask,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              input: input
            })
        });
      debug(`setReaderDoorForcedMask() input is ${JSON.stringify(input)}`);
      const response = await API.graphql(graphqlOperation(setReaderDoorForcedMaskMutation,
        {
          input: input
        })) as GraphQLResult<APIt.SetReaderDoorForcedMaskMutation>;
      createUserAction(
        {
          actionName: UserActionNames.SetReaderDoorForcedMaskResult,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              response: response,
              input: input
            })
        });
      debug(`setReaderDoorForcedMask() response is ${JSON.stringify(response)}`);
      setReaderDoorForcedMaskResponse.status = response.data?.setReaderDoorForcedMask?.status;
      setReaderDoorForcedMaskResponse.requestUUID = response.data?.setReaderDoorForcedMask?.requestUUID;
      if (response.data?.setReaderDoorForcedMask?.devices)
        setReaderDoorForcedMaskResponse.devices = response.data?.setReaderDoorForcedMask?.devices as APIt.SetDeviceResponse[];
      debug(`setReaderDoorForcedMask() setReaderDoorForcedMaskResponse is ${JSON.stringify(setReaderDoorForcedMaskResponse)}`);
      resolve(setReaderDoorForcedMaskResponse);
    } catch (error: any) {
      console.error('setReaderDoorForcedMask() error is ', error);
      createUserAction(
        {
          actionName: UserActionNames.SetReaderDoorForcedMaskError,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              error: error,
              input: input
            })
        });
      if (error.errors[0]) {
        setReaderDoorForcedMaskResponse.error = error.errors[0].message;
      } else {
        setReaderDoorForcedMaskResponse.error = error;
      }
      reject(setReaderDoorForcedMaskResponse);
    }
  });
}

export const setOnguardReaderMasking = async (
  region: string,
  devices: SilencioDevice[],
  actionTypeId: number,
  masked: boolean,
  requestedBy: string,
  delay: number): Promise<DeviceMutationResponseInterface> => {
  debug(`setOnguardReaderMasking() region is ${region} devices is ${JSON.stringify(devices)} actionTypeId is ${actionTypeId} masked is ${masked} requestedBy is ${requestedBy} delay is ${delay}`);

  let setOnguardReaderMaskResponse: DeviceMutationResponseInterface = { devices: [], error: null, requestUUID: null, status: 202 };

  let stage = 'beta';

  for (let device of devices) {
    if (!device.DeviceName) continue;
    let path = `${Paths.OnguardReaderMasking}/region/${region}/stage/${getPACSDataAPIStage(stage)}/`;
    path += `?ACTION_TYPEID=${actionTypeId}&SEGMENTID=${device.SegmentID}&PANELID=${device.Parent_DeviceID}&DEVICEID=${device.Child_DeviceID}&EXECUTION_VALUE=${masked ? 1 : 0}&RUNAFTER_SECONDS=${delay}&outputformat=json`
    const isDoorForced = (actionTypeId === OnguardReaderMaskActionTypes.doorForcedOpen);
    debug(`setOnguardReaderMasking() path is ${path}`);
    try {
      createUserAction(
        {
          actionName: isDoorForced ? UserActionNames.SetReaderDoorForcedMask : UserActionNames.SetReaderDoorHeldMask,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              input: path
            })
        });
      const response = await API.graphql(graphqlOperation(setPACSDataAPIReaderMaskMutation,
        {
          path
        })) as GraphQLResult<APIt.SetPACSDataAPIReaderMaskMutation>;
      createUserAction(
        {
          actionName: isDoorForced ? UserActionNames.SetReaderDoorForcedMaskResult : UserActionNames.SetReaderDoorHeldMaskResult,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              response: response,
              input: path
            })
        });
      debug(`setOnguardReaderMasking() response is ${JSON.stringify(response)}`);
      setOnguardReaderMaskResponse.devices.push(
        {
          __typename: "SetDeviceResponse",
          deviceName: device.DeviceName,
          status: (response.data?.setPACSDataAPIReaderMask?.status === 200 ? 'Success' : 'Failure')
        }
      );
    } catch (error) {
      console.error('setOnguardReaderMasking() error is ', error);
      createUserAction(
        {
          actionName: isDoorForced ? UserActionNames.SetReaderDoorForcedMaskError : UserActionNames.SetReaderDoorHeldMaskError,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              error: error,
              input: path
            })
        });
    }
  }

  return new Promise(async (resolve, reject) => {
    debug(`setOnguardReaderMasking() setOnguardReaderMaskResponse is ${JSON.stringify(setOnguardReaderMaskResponse)}`);
    return resolve(setOnguardReaderMaskResponse);
  });
}

export const setOutputCommand = async (
  command: string,
  deviceSource: string,
  devices: { deviceName: string, deviceHref: string | undefined, deviceType: string | undefined }[],
  emails: string,
  processWithDelaySeconds: number,
  requestedBy: string,
  siteName: string,
  siteRegion: string): Promise<DeviceMutationResponseInterface> => {
  debug(`setOutputCommand() command is ${command} deviceSource is ${deviceSource} devices is ${JSON.stringify(devices)} emails is ${emails} processWithDelaySeconds is ${processWithDelaySeconds} requestedBy is ${requestedBy} siteName is ${siteName} siteRegion is ${siteRegion}`);

  let setOutputCommandResponse: DeviceMutationResponseInterface = {
    devices: devices.map(d => {
      return {
        __typename: 'SetDeviceResponse',
        deviceName: d.deviceName,
        status: null
      }
    }),
    error: null,
    requestUUID: null,
    status: null
  };

  return new Promise(async (resolve, reject) => {
    const input = {
      command: command,
      deviceSource: deviceSource,
      devices: devices,
      emails: emails || '',
      method: 'post',
      path: Paths.OutputCommand,
      processWithDelaySeconds: processWithDelaySeconds,
      requestedBy: requestedBy,
      siteName: siteName,
      siteRegion: siteRegion
    };
    try {
      createUserAction(
        {
          actionName: UserActionNames.SetOutputCommand,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              input: input
            })
        });
      debug(`setOutputCommand() input is ${JSON.stringify(input)}`);
      const response = await API.graphql(graphqlOperation(setOutputCommandMutation,
        {
          input: input
        })) as GraphQLResult<APIt.SetOutputCommandMutation>;
      createUserAction(
        {
          actionName: UserActionNames.SetOutputCommandResult,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              response: response,
              input: input
            })
        });
      debug(`setOutputCommand() response is ${JSON.stringify(response)}`);
      setOutputCommandResponse.status = response.data?.setOutputCommand?.status;
      setOutputCommandResponse.requestUUID = response.data?.setOutputCommand?.requestUUID;
      if (response.data?.setOutputCommand?.devices)
        setOutputCommandResponse.devices = response.data?.setOutputCommand?.devices as APIt.SetDeviceResponse[];
      debug(`setOutputCommand() setOutputCommandResponse is ${JSON.stringify(setOutputCommandResponse)}`);
      resolve(setOutputCommandResponse);
    } catch (error: any) {
      console.error('setOutputCommand() error is ', error);
      createUserAction(
        {
          actionName: UserActionNames.SetOutputCommandError,
          username: requestedBy,
          parameters: JSON.stringify(
            {
              error: error,
              input: input
            })
        });
      if (error.errors[0]) {
        setOutputCommandResponse.error = error.errors[0].message;
      } else {
        setOutputCommandResponse.error = error;
      }
      reject(setOutputCommandResponse);
    }
  });
}